@keyframes spinner-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner-container {
  width: 20px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.spinner div {
  left: 7px;
  top: 0px;
  position: absolute;
  animation: spinner-animation linear 1s infinite;
  background: #325bed;
  width: 2px;
  height: 4px;
  border-radius: 1px / 2px;
  transform-origin: 1px 7px;
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.91s;
}
.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.83s;
}
.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
}
.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.66s;
}
.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.58s;
}
.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
}
.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.41s;
}
.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.33s;
}
.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
}
.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16s;
}
.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.083s;
}
.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
