.slider {
  width: 100%;
  max-width: 500px;
  height: 40px;

  .slider-track {
    height: 4px;
    background: #f3f4f6;
  }

  .slider-thumb {
    min-width: 18px;
    width: 18px;
    height: 18px;
    background: "#325BED";
  }
}
