@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  font-feature-settings: "cv11";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer base {
  h1 {
    @apply text-[2rem];
    @apply font-semibold;
    @apply my-[0.67em];
  }
  h2 {
    @apply text-[1.5rem];
    @apply font-semibold;
    @apply my-[0.75em];
  }
  h3 {
    @apply text-[1.17rem];
    @apply font-semibold;
    @apply my-[0.83em];
  }
  h4 {
    @apply text-[1rem];
    @apply font-semibold;
    @apply my-[1.12em];
  }
  h5 {
    @apply text-[0.83rem];
    @apply font-semibold;
    @apply my-[1.5em];
  }
  h6 {
    @apply text-[0.67rem];
    @apply font-semibold;
    @apply my-[1.67em];
  }
  p {
    @apply my-[1em];
  }
}
